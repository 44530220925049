<template lang="pug">
.profile.pb-5
  AppTitle(label="Profile")
  AppFormInput(type="text" label="Nombre" :value="user.name" disabled)
  AppFormInput(type="text" label="Area" :value="user.area" disabled)
  AppFormInput(type="text" label="Email" :value="user.work_email" disabled)
</template>

<script>
import AppTitle from "@/components/AppTitle";
import { computed } from "vue";
import { AppFormInput } from "gignet-components";
import { store } from "@/store";

export default {
  components: { AppFormInput, AppTitle },
  setup() {
    const user = computed(() => store.user);

    return { user };
  },
};
</script>
